import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import { accountStateSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { logout } from 'state/account/account.reducers';
import { accountForgotUsernameRoutine } from 'state/account/account.routines';

import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import ForgotUsernameForm, {
    ForgotUsernameFailureModalContent,
    ForgotUsernameSuccessModalContent
} from 'components/forgot-username-form/forgot-username-form.component';
import { ForgotUsernameFormValues } from 'components/forgot-username-form/forgot-username-form.props';

const ForgotUsername = (): ReactElement => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isSubmittingUsernameRequest } = useSelector(accountStateSelector);

    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const handleSubmitForgotUsernameForm = (values: ForgotUsernameFormValues) => {
        dispatch(
            accountForgotUsernameRoutine.trigger({
                email: values.email,
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            onClose: () => navigate('/sign-in'),
                            bodyContent: <ForgotUsernameSuccessModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.forgotUsernameSuccess.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        navigate('/sign-in');
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            onClose: () => {
                                dispatch(closeModal({}));
                            },
                            bodyContent: <ForgotUsernameFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.forgotUsernameFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    useEffect(() => {
        return () => {
            dispatch(logout({}));
        };
    }, [dispatch]);

    return (
        <WorkflowLayout
            backgroundImage={imageData.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('forgotUsername.labels.title') }}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t('forgotUsername.labels.eyebrowText')}
                title={t('forgotUsername.labels.title')}
            >
                <ForgotUsernameForm
                    onSubmit={handleSubmitForgotUsernameForm}
                    isSubmittingUsernameRequest={isSubmittingUsernameRequest}
                />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default ForgotUsername;
